import Vue from 'vue'
import Vuex from 'vuex'
import mutations from "../store/mutations";
import api from "../api";
import log from "../log";
import moment from "moment";

import router from "../router";
import consts from "@/utils/consts";
import config from "@/config";
Vue.use(Vuex)

const state = {
  router : router,
  token : sessionStorage.token || null,
  drawer : true,
  loading : false,
  loadingMessage : 'Loading...',
  profile : null,
  dialogMessage : '',
  snackbar : false,
  snackbarColor : 'info',
  confirmDialog : false,
  confirmDialogTitle: 'Richiesta conferma',
  confirmDialogMessage : 'Sei sicuro?',
  confirmDialogCallback : null,
  cities : [],
  tags : [],
  contracts : [],
  news : [],
  scheda : null,
};

const getters = {
  loggedIn: () => !!state.profile,
  isRestrictedRoute: () => !['login','notfound'].includes(router.currentRoute.name),
  token : () => state.token,
  profile : () => state.profile,
  hasNewsContract : () => !!state.contracts && state.contracts.length > 0 && state.contracts.filter(item => item.max_news > 0).length > 0,
  cities : () => state.cities,
  tags : () => state.tags,
  contracts : () => state.contracts,
  news : () => state.news,
  scheda : () => state.scheda,
}

const actions = {
  async login({commit}, { username, password}) {
    try {
      let reply = await api.login( username, password )
      if(reply && reply.token) {
        log.d("login success", reply)
        commit('token', { token : reply.token });
        commit('profile', { profile : reply.user })
        return reply;
      }
    } catch (e) {
      log.d("login error:", e);
      return Promise.reject(e)
    }
  },
  async resetUserPassword({commit}, { email }) {
    try {
      let reply = await api.resetUserPassword( email )
      log.d("reset password success", reply)
      return reply;
    } catch (e) {
      log.w("reset password error:", e);
      return Promise.reject(e)
    }
  },
  async refreshToken({ commit }) {
    const token = sessionStorage.token;
    if(!token) {
      return Promise.reject({status : 401, message: 'utente non loggato'})
    }
    log.d("token in session is:", token)
    try {
      let reply = await api.handshake(token);
      if(reply) {
        commit('token', { token : reply.token });
        let userReply = await api.getProfile(reply.token)
        if(userReply) {
          commit('profile', { profile : userReply.data });
          log.d('profile loaded')
        }
      }
      return true
    } catch (e) {
      log.w("refresh token error:", e);
      return Promise.reject(e)
    }
  },
  async loadProfile({ commit }) {
    const token = sessionStorage.token;
    if(!token) {
      return Promise.reject({status : 401, message: 'utente non loggato'})
    }
    try {
      let reply = await api.getProfile(token);
      if(reply) {
        log.d("got profile", reply)
        commit('profile', { profile : reply.data });
      }
      return true
    } catch (e) {
      log.w("loadProfile error:", e);
      return Promise.reject(e)
    }
  },
  async profileUpdate({ commit },{ data }) {
    const token = sessionStorage.token;
    if(!token) {
      return Promise.reject({status : 401, message: 'utente non loggato'})
    }
    try {
      let reply = await api.saveProfile(token, data);
      if(reply) {
        log.d("saved profile", reply)
        commit('profile', { profile : reply.user });
        commit('token', { token : reply.token });
      }
      return true
    } catch (e) {
      log.w("saveProfile error:", e);
      return Promise.reject(e)
    }
  },
  async changePassword({ commit },{ mail, oldPassword, password }) {
    let reply
    try {
      reply = await api.login( mail, oldPassword )
    } catch (err) {
      throw new Error("La vecchia password è sbaglia");
    }
    let user = reply.user;
    user.password = password
    let token = reply.token;

    reply = await api.saveProfile(token, user);
    commit('profile', { profile : reply.user });
    commit('token', { token : reply.token });
  },
  logout({commit}) {
    commit('logout')
  },
  sendSuccess({ commit }, { message }) {
    commit('message', { type : 'success', message })
  },
  sendWarning({ commit }, { message }) {
    commit('message', { type : 'warning', message })
  },
  sendError({ commit }, { message }) {
    commit('message', { type : 'error', message })
  },
  sendInfo({ commit }, { message }) {
    commit('message', { type : 'info', message })
  },
  requestConfirm({commit}, { title, message, callback }) {
    commit('confirm', { title, message, callback })
  },
  resendUserConfirm({commit},{ mail }) {
    //TODO /resend/confirm
    return api.resendConfirmAccount(mail)
  },
  load({commit}, { collection, filter }) {
    let { query, limit, page, sort  } = filter;
    const token = sessionStorage.token
    if(!token) {
      return Promise.reject({status: 401, message: 'non sei autenticato!'});
    }
    return api.restLoad( token, collection, query, limit, page, sort );
  },
  loadNoAuth({commit}, { collection, filter }) {
    let { query, limit, page, sort } = filter;
    return api.restLoad( null, collection, query, limit, page, sort);
  },
  rawGet({commit}, { url }) {
    return api.rawGet( url );
  },
  get({commit}, { collection, id }) {
    const token = sessionStorage.token
    if(!token) {
      return Promise.reject({status: 401, message: 'non sei autenticato!'});
    }
    return api.restGet( token, collection, id);
  },
  insert({commit}, { collection, data }) {
    const token = sessionStorage.token
    if(!token) {
      return Promise.reject({status: 401, message: 'non sei autenticato!'});
    }
    return api.restPost( token, collection, data );
  },
  upload({commit}, { path, data }) {
    const token = sessionStorage.token
    if(!token) {
      return Promise.reject({status: 401, message: 'non sei autenticato!'});
    }
    return api.restUpload( token, path, data );
  },
  update({commit}, { collection, data }) {
    const token = sessionStorage.token
    if(!token) {
      return Promise.reject({status: 401, message: 'non sei autenticato!'});
    }
    return api.restPut( token, collection, data );
  },
  remove({commit}, { collection, id }) {
    const token = sessionStorage.token
    if(!token) {
      return Promise.reject({status: 401, message: 'non sei autenticato!'});
    }
    return api.restDelete( token, collection, id );
  },
  getNoAuth({commit}, { collection, id }) {
    return api.restGet( null, collection, id);
  },
  async translate({commit},{ text }) {
    let reply = await api.googleTranslate(text);
    if(!reply.data || !reply.data.translations || !reply.data.translations.length) {
      throw new Error("Impossibile tradurre automaticamente, perfavore inserisci una traduzione manualmente.")
    }
    return reply.data.translations[0].translatedText
  },
  async refreshCollection({ commit }, { collection }) {
    log.d("refreshCollection()", collection)
    if(state[collection] && state[collection].length) return true;
    let reply = await api.restLoad( sessionStorage.token, collection, { city : 'all' }, 0, 1, null);
    log.d("refreshCollection() done", collection)
    if(reply.data) {
      commit(collection, { data : reply.data });
      return true
    }
    return false
  },
  async refreshContracts({ commit }) {
    try {
      let reply = await api.restGet(sessionStorage.token, "my", "contracts")
      log.d("refreshContracts() done")
      if(reply.data) {
        commit('contracts', { data : reply.data });
      }
      return reply
    } catch (err) {
      log.d("refreshContracts() error:", err)
      throw err
    }
  },
  async refreshSchede({ commit }) {
    try {
      let reply = await api.restGet(sessionStorage.token, "my", "schede")
      log.d("refreshSchede() done")
      if(reply.data) {
        commit('scheda', { data : reply.data.filter(item => item.type === 'locale')[0] });
        commit('news', { data : reply.data.filter(item => item.type === 'news') });
      }
      return reply
    } catch (err) {
      log.d("refreshSchede() error:", err)
      throw err
    }
  }
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
})
