<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card>
          <v-form ref="profileForm">
            <v-card-title><h2 class="gill-bold" style="line-height: 0.8">Profilo</h2></v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-avatar size="100"><v-img :src="profileImage" class="white"/></v-avatar>
                </v-col>
                <v-col cols="12" v-if="editMode">
                  <v-row>
                    <v-col cols="12" lg="6"><v-text-field :rules="[rules.required, rules.email]" outlined label="Email*" type="email" v-model="item.mail" /></v-col>
                    <v-col cols="12" lg="6"><v-text-field :rules="[rules.required]" outlined label="Nome*" v-model="item.name" /></v-col>
                    <v-col cols="12" lg="6"><v-text-field :rules="[rules.required]" outlined label="Cognome*" v-model="item.surname" /></v-col>
                    <v-col cols="12" lg="6"><v-text-field outlined label="Data di nascita" type="date" clearable v-model="born" /></v-col>
                    <v-col cols="12" lg="6"><v-select outlined label="Genere" :items="genderOptions" v-model="item.gender" /></v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" v-else>
                  <p><b class="gill-bold">Email:</b> {{item.mail}}</p>
                  <p><b class="gill-bold">Nome:</b> {{item.name}}</p>
                  <p><b class="gill-bold">Cognome:</b> {{item.surname}}</p>
                  <p><b class="gill-bold">Data di nascita:</b> <span v-if="item.born">{{formatDate(item.born)}}</span><span v-else>-</span></p>
                  <p><b class="gill-bold">Genere:</b>  {{getGenderName(item.gender)}}</p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn :loading="loading" text color="accent" @click="editMode = !editMode; loadData()">{{ editMode ? 'Annulla' : 'Modifica' }}</v-btn>
              <v-btn :loading="loading" text color="accent" v-if="editMode" @click="saveProfile()">Salva</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card>
          <v-form ref="passwordForm">
            <v-card-title><h2 class="gill-bold" style="line-height: 0.8">Cambia password</h2></v-card-title>
            <v-card-text  v-if="editPasswordMode">
              <v-row>
                <v-col cols="12">
                  <v-text-field :rules="[rules.required]" outlined label="Vecchia password" v-model="oldPassword" :type="showOldPwd ? 'text' : 'password'" :append-icon="showOldPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showOldPwd = !showOldPwd"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field :rules="[rules.required, rules.password]" outlined label="Nuova password" v-model="password" :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPwd = !showPwd"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field :rules="[rules.required, rules.confirmPassword(password)]" outlined label="Conferma password" v-model="confirmPassword" :type="showConfPwd ? 'text' : 'password'" :append-icon="showConfPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showConfPwd = !showConfPwd"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn :loading="loading" text color="accent" @click="editPasswordMode = !editPasswordMode">{{ editPasswordMode ? 'Annulla' : 'Modifica' }}</v-btn>
              <v-btn :loading="loading" text color="accent" v-if="editPasswordMode" @click="savePassword()">Imposta nuova password</v-btn>
            </v-card-actions>
          </v-form>

        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import placeholder from '../assets/placeholder.svg'
import moment from "moment";
import utils from "@/utils";
import log from "@/log";
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
import rules from "@/utils/rules";
export default {
  name: "Account",
  data () {
    return {
      loading : false,
      editMode : false,
      editPasswordMode : false,
      showPwd : false,
      showConfPwd : false,
      showOldPwd : false,
      rules : rules,
      item : {
        mail : '',
        name : '',
        surname : '',
        born : null,
        gender : null,
        img : '',
      },
      oldPassword : '',
      password : '',
      confirmPassword : '',
      genderOptions : [{ text : 'Donna', value : 'female' },{ text : 'Uomo', value : 'male' },{ text : 'Non dichiarato', value : null }]
    };
  },
  computed : {
    ...mapGetters(['profile']),
    profileImage() {
      return this.item.img || placeholder
    },
    born : {
      get() {
        if(!this.item.born) return null;
        let date = moment(this.item.born)
        if(date.isValid()) {
          return date.format('YYYY-MM-DD');
        }
        return null
      },
      set(val) {
        if(!val) this.item.born = null
        let date = moment(val, 'YYYY-MM-DD')
        if(date.isValid()) {
          this.item.born = date.toJSON()
          return
        }
        this.item.born = null;
      }
    }
  },
  methods : {
    ...mapActions(['profileUpdate','sendError','sendSuccess','changePassword']),
    getGenderName( gen ) {
      switch (gen) {
        case 'male':
          return "Uomo";
        case 'female':
          return "Donna";
      }
      return "Non dichiarato"
    },
    formatDate(date) {
      if(!date) return '-';
      return utils.formatDate(date)
    },
    loadData() {
      this.item = utils.copy(this.profile)
    },
    async saveProfile() {
      if(!this.$refs.profileForm.validate()) {
        return;
      }
      this.loading = true;
      try {
        await this.profileUpdate({data:this.item});
        this.sendSuccess({message: 'Profilo aggiornato!'})
      } catch (err) {
        log.d("profile update error", err)
        this.sendError({message:'Oops! Qualcosa è andato storto :(. Riprova più tardi'});
      }
      this.loading = false;
      this.loadData()
    },
    async savePassword() {
      if(!this.$refs.passwordForm.validate()) {
        return;
      }
      this.loading = true;
      try {
        await this.changePassword({ mail : this.profile.mail, oldPassword : this.oldPassword, password: this.password});
        this.sendSuccess({message: 'Password aggiornata!'});
      } catch (e) {
        let message = e.message || "Oops! Qualcosa è andato storto :(. Riprova più tardi";
        this.sendError({message});
      }
      this.loading = false;
      this.loadData()
    }
  },
  mounted() {
    let wait = setInterval(() => {
      if(!this.profile) return;
      this.loadData();
      clearInterval(wait);
    }, 500);

  }
}
</script>

