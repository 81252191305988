import _ from "lodash";
import moment from 'moment'
moment.locale('it')
import config from '../config'
const getChildrenOf = (ctg, map) => {
    if(map[ctg.id]) {
        let children = map[ctg.id];
        for(let i = 0; i < children.length; i++) {
            let item = children[i];
            children[i] = getChildrenOf(item, map);
        }
        ctg.children = children;
    }
    return ctg;
};


const utils = {
    zeroPad : ( number, width ) => {
        width -= number.toString().length;
        if ( width > 0 ) {
            return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
        }
        return number + ""; // always return a string
    },
    checkSessionError(err) {
        if(err.status === 401) {
            const message = err && err.message ? err.message : 'invalid or expired session'
            this.sendError({ message });
            this.logout();
            return false
        }
        return true
    },
    isValidPassword( password ) {
        return /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)
    },
    isValidEmail( mail ) {
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(mail)
    },
    isValidColor( hex ) {
        return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex)
    },
    isValidUrl( url ) {
        return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i.test(url)
    },
    isValidPhone( value ) {
        return /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/i.test(value)
    },
    getLangVal(labels, lng) {
        if(!labels || !labels.length) return '';
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].lng === lng) {
                return labels[i].val;
            }
        }
        return '';
    },
    getContactsFormatted(contacts, type) {
        if(!contacts || !contacts.length) return ''
        let filter = contacts.filter((item) => item.type === type)
        if(!filter.length) return '';
        let values = filter.map((item) => item.value)
        return values.join(", ");
    },
    setContactValue(contacts, newVal, type, is_action) {
        let vals = newVal.split(", ")
        let parsed = vals.map((value) => {
            return {type, value, is_action}
        });
        if(!contacts || !contacts.length) {
            if(!newVal) {
                return [];
            }
            return parsed;
        }
        let others = contacts.filter((item) => item.type !== type)
        if(!newVal) {
            return others;
        }
        return others.concat(parsed);
    },
    setLangVal(labels, lng, val) {
        if(!labels || !labels.length) return [{lng, val}];
        for (let i = 0; i < labels.length; i++) {
            if (labels[i].lng === lng) {
                labels[i].val = val;
                return labels
            }
        }
        labels.push({lng, val})
        return labels;
    },
    toFloat(val, defaultVal) {
        if(_.isNil(val)) return defaultVal;
        if(_.isNumber(val)) return val;
        if(_.isString(val)) {
            let num = parseFloat(val.replace(",","."));
            if(!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    toInt(val, defaultVal) {
        if(_.isNil(val)) return defaultVal;
        if(_.isNumber(val)) return val;
        if(_.isString(val)) {
            let num = parseInt(val.replace(",","."), 10);
            if(!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    splitUrl(url) {
        if(!url) return { path : null, name : null };
        let spl = url.split("/");
        if(spl.length === 1) return { path : '/', name : spl[0]};
        let name = spl.pop();
        let path = spl.join("/") + "/";
        return { path, name };
    },
    xlimageThumb(imgf, w, h, q) {
        if(!q) {
            q=70;
        }
        return imgf + "?cmd=whole&w=" + w + "&h=" +  h + "&q=" + q;
    },
    xlimageThumbHotspotURL(imgf, hsp, w, h, q) {
        if(!q) {
            q=70;
        }
        let spl = imgf.split("closer");

        if(spl.length !== 2) return utils.xlimageThumb(imgf, w, h, q);

        let url = config.server + "/xlimage/hotspot" + spl[1];
        url+="?x=" + hsp.x + "&y=" + hsp.y + "&w=" + hsp.w + "&h=" + hsp.h + "&tw=" + w + "&th=" + h;
        return url;
    },
    getHotspotsByCategoryID(hotspots, artworks, ctgID, limit) {
        if(!limit) limit = 0;
        let results = [];
        let h;
        for(let i = 0; i < hotspots.length; i++) {
            h = hotspots[i];
            if(h.category_id === ctgID) {
                h.priority = utils.getArtworkPriority(artworks, h.artwork_id);
                results.push(h);
            }
        }
        results.sort(utils.sortFunction("priority", "desc"));
        if(limit > 0 && results.length > limit) {
            results = results.slice(0, limit);
        }
        return results;
    },
    getRootCategoryID(categories, categoryID) {
        let ctg = categories[categoryID];
        if(!ctg) {
            return '';
        }
        if(!ctg.parent_id){
            return ctg.id
        }
        let breakLoopCounter = 10;
        while(ctg.parent_id && categories[ctg.parent_id]) {
            ctg = categories[ctg.parent_id];
            if(!ctg.parent_id) {
                return ctg.id;
            }
            if(breakLoopCounter <= 0) {
                break;
            }
            breakLoopCounter--;
        }
        return '';
    },
    getSiblingsCategories(categories, ctgID) {
        let ctg = categories[ctgID];
        if(!ctg) {
            console.warn("Richiesti i siblings di una categoria inesistente:", ctgID);
            return [];
        }
        if(!ctg.parent_id) {
            console.warn( "Richiesti i siblings di una categoria radice:", ctgID);
            return [];
        }
        let results = [];
        for(let id in categories) {
            let loopingCtg = categories[id];
            if(loopingCtg.id === ctgID) {
                continue;
            }
            if(ctg.parent_id === loopingCtg.parent_id) {
                results.push(loopingCtg);
            }
        }
        return results;
    },
    getHotspotsCategories(categoriesMap, map, orderMap) {
        let categories = [];
        for(const ctg_id in map) {
            let ctg = _.clone(categoriesMap[ctg_id]);
            ctg.points = orderMap[ctg_id] || 10000000;
            categories.push(ctg);
        }
        categories.sort(utils.sortFunction("points", "asc"));
        return categories;
    },
    isHotspotInBounds(hotspot, bounds) {
        const hotspotBounds = {x:hotspot.x, y:hotspot.y, width:hotspot.w, height:hotspot.h};
        return utils.areaOverlap(hotspotBounds, bounds)
    },
    getHotspotsInBounds(hotspots, artworkID, bounds, restrictRatio, zoomPercent) {
        let hotspotsInBounds = [];
        // calcolo i bounds ristretti
        let restrictedBounds = {};
        restrictedBounds.width = bounds.width - (bounds.width * restrictRatio.x);
        restrictedBounds.height = bounds.height - (bounds.height * restrictRatio.y);
        restrictedBounds.x = bounds.x - (bounds.width/2 - restrictedBounds.width/2);
        restrictedBounds.y = bounds.y - (bounds.height/2 - restrictedBounds.height/2);

        let hsp;

        for(let i = 0; i < hotspots.length; i++) {
            hsp = hotspots[i];
            if(hsp.artwork_id === artworkID && // stesso artwork
                utils.isHotspotInBounds(hsp, restrictedBounds) && // interseca il bounds
                utils.valueInRange(zoomPercent, hsp.min_z, hsp.max_z)){ // è in un range di zoom valido
                hotspotsInBounds.push(hsp);
            }
        }
        var currentCenter = utils.getRectCenter(bounds);
        // ordina per distanza dal centro (ascendente)
        hotspotsInBounds.sort((a, b) => {
            var centerA = utils.getRectCenter({x:a.x, y:a.y, width:a.w, height:a.h});
            var centerB = utils.getRectCenter({x:b.x, y:b.y, width:b.w, height:b.h});
            var distanceA = utils.calculateDistanceBetweenTwoPoints(currentCenter, centerA);
            var distanceB = utils.calculateDistanceBetweenTwoPoints(currentCenter, centerB);
            return distanceA - distanceB;
        });

        return hotspotsInBounds;
    },
    getArtworkPriority(artworks, artworkID) {
        let a;
        for(let i = 0; i < artworks.length; i++) {
            a = artworks[i];
            if(a.id === artworkID) {
                return a.priority;
            }
        }
        return -1;
    },
    calculateNumPoints(bounds, hsp) {
        const hspCenter = utils.getRectCenter({x:hsp.x, y:hsp.y, width:hsp.w, height:hsp.h})
        const boundsCenter = utils.getRectCenter(bounds);
        const distance = utils.calculateDistanceBetweenTwoPoints(boundsCenter, hspCenter);
        return Math.round(distance * 1000000);
    },
    sortFunction(property, direction) {
        if(!direction) direction = "asc";
        const one = direction === "asc" ? 1 : -1;
        return (a, b) => {
            if(a[property] > b[property]) {
                return one;
            }
            if(a[property] < b[property]) {
                return -one;
            }
            return 0;
        }
    },
    valueInRange(value, min, max) {
        return (value <= max) && (value >= min);
    },
    areaOverlap(A, B) {
        const xOverlap = utils.valueInRange(A.x, B.x, B.x + B.width) || //utils.valueInRange(val:A.x, min:B.x, maxB.x + B.width)
            utils.valueInRange(B.x, A.x, A.x + A.width);
        const yOverlap = utils.valueInRange(A.y, B.y, B.y + B.height) ||
            utils.valueInRange(B.y, A.y, A.y + A.height);
        return xOverlap && yOverlap;
    },
    getRectCenter(rect) {
        return {
            x : rect.x + rect.width / 2,
            y : rect.y + rect.height / 2
        }
    },
    calculateDistanceBetweenTwoPoints(pointA, pointB) {
        const xq = Math.pow(pointB.x - pointA.x, 2);
        const yq = Math.pow(pointB.y - pointA.y, 2);
        return Math.sqrt(xq + yq);
    },
    capText(text, maxChars) {
        if(text.length > maxChars) {
            return text.substr(0, maxChars-3) + "..."
        }
        return text
    },
    copy : ( obj ) => {
        return JSON.parse(JSON.stringify(obj));
    },
    formatDate(date) {
        if(!date) return '-';
        return moment(date).format('DD/MM/YYYY')
    },
    formatDateTime(date) {
        if(!date) return '-';
        return moment(date).format('DD/MM/YYYY, HH:mm')
    },
    bind() {
        if(arguments.length < 2) return;
        const _this = arguments[0];
        for(let i = 1; i < arguments.length; i++) {
            let fn = arguments[i];
            _this[fn] = _this[fn].bind(_this);
        }
    },
    getInnerHeight( domEl ) {
        const computed = getComputedStyle(domEl);
        const paddingH = parseInt(computed.paddingTop) + parseInt(computed.paddingBottom);
        return domEl.clientHeight - paddingH;
    },
    getObjectInArray(items, byKey, value) {
        let filter = items.filter(item => item[byKey] === value)
        return filter.length ? filter[0] : null;
    }
};
export default utils;