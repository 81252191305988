<template>
  <v-container fluid class="fill-height">
    <div class="d-flex justify-center align-center fill-height full-width">
      <v-row class="d-flex justify-center" style="max-width: 360px">
        <v-col cols="12" class="white--text" style="position: relative"  >
          <v-img src="../assets/logo-bianco.png" style="width: 100%;" contain max-width="360" :aspect-ratio="1"  />
          <span class="display-1 font-weight-black d-block mr-16 mb-10 gill-bold" style="position:absolute; bottom: 0; right: 0">NEWS</span>
        </v-col>
        <v-col cols="12">
          <v-form @submit="onSubmit">
            <v-text-field
                dark
                class="gill-semibold"
                color="white"
                v-model="username"
                label="Email"
                name="email"
                type="email"
                outlined
                :error="usernameError"
                :error-messages="usernameErrorMessage"
            ></v-text-field>
            <v-text-field
                dark
                class="gill-semibold"
                color="white"
                v-model="password"
                name="password"
                label="Password"
                outlined
                :error="passwordError"
                :error-messages="passwordErrorMessage"
                id="password"
                :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPwd = !showPwd"></v-text-field>

            <v-btn class="flex-grow-1 flex-shrink-0 full-width accent--text gill-bold" color="white" type="submit" :loading="loading">Login</v-btn>
          </v-form>
        </v-col>

        <v-col cols="12" class="mt-3 text-center">
          <p class="mb-0 white--text gill-semibold">Non hai ancora un account? <a @click="$router.push('/register')" class="white--text gill-bold text-decoration-underline">Registrati!</a></p>
        </v-col>
        <v-col cols="12" class="text-center" >
          <a @click="resendPasswordDialog = true" class="white--text gill-bold text-decoration-underline">Hai perso la password?</a>
        </v-col>
      </v-row>

    </div>

    <v-dialog v-model="resendPasswordDialog" max-width="360">
      <v-card>
        <v-card-title>Hai perso la password?<v-spacer/><v-btn color="accent" icon @click="resendPasswordDialog = false"><v-icon>mdi-close</v-icon></v-btn></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              Inserisci qui la mail del tuo account, ti invieremo una mail con le istruzioni per reimpostare la tua password
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="username"
                  label="Email"
                  type="email"
                  :error="resetError"
                  :error-messages="usernameErrorMessage"
                  outlined
              ></v-text-field>
              <v-btn class="flex-grow-1 flex-shrink-0 full-width" color="accent" :loading="loading" @click="resetPassword()">Reimposta password</v-btn>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="noUserConfirmDialog" persistent max-width="400" >
      <v-card>
        <v-card-title class="headline">Non hai ancora confermato il tuo account!</v-card-title>
        <v-card-text>
          <p class="body-1">Per confermare il tuo account controlla la tua casella di posta e segui il link nella mail che ti abbiamo inviato. <br>Controlla anche nello spam se non trovi la mail all'interno della posta in arrivo.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="resendConfirmMail()">Reinvia mail</v-btn>
          <v-btn color="error" text @click.native="noUserConfirmDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import utils from '../utils'
import consts from '../utils/consts'
import log from '../log'
import config from "@/config";
export default {
  name: "Login",
  data() {
    return {
      noUserConfirmDialog : false,
      resendPasswordDialog : false,
      loading : false,
      showPwd : false,
      username : '',
      password: '',
      usernameError: false,
      passwordError: false,
      resetError: false,
    }
  },
  computed : {
    ...mapGetters(['hasNewsContract']),
    usernameErrorMessage() {
      return this.usernameError ? ['inserisci a una mail valida'] : []
    },
    passwordErrorMessage() {
      return this.passwordError ? ["la password non è valida o l'account non esiste"] : []
    },
  },
  methods : {
    ...mapActions(['login','logout','loadProfile','refreshCollection','sendSuccess','sendError',
      'resetUserPassword','requestConfirm','resendUserConfirm','refreshContracts','refreshSchede']),
    onError(e) {
      if(e) {
        log.d(e)
        this.passwordError = true;
        this.loading = false;
        this.logout()
      }
    },
    async resetPassword() {
      this.resetError = !utils.isValidEmail(this.username)
      if(this.resetError) return;
      this.loading = true;
      try {
        await this.resetUserPassword({email: this.username})
        this.sendSuccess({message : 'Ti abbiamo inviato una mail sulla tua casella di posta'})
        this.loading = false;
        this.resendPasswordDialog = false;
      } catch(err) {
        let message = err.message ? err.message : "Ci dispiace, c'è stato un errore del server nel processare la richiesta. Perfavore riprova più tardi"
        this.sendError({message});
        this.loading = false;
        this.resendPasswordDialog = false;
      }
    },
    async resendConfirmMail() {
      try {
        await this.resendUserConfirm({ mail: this.username})
      } catch (e) {
        const message = e && e.message ? e.message : "Oops! C'è stato un errore, perfavore riprova più tardi."
        this.sendError({message})
      }
      this.noUserConfirmDialog = false;
    },
    async onSubmit(e) {
      e.preventDefault();
      this.usernameError = this.username === '';
      this.passwordError = this.password === '';
      if(this.passwordError || this.usernameError) return;
      this.loading = true;
      try {
        let reply = await this.login({username: this.username, password: this.password})
        this.loading = false;
        if(reply.user.role === consts.USER_ROLES.USER) {
          //utente
          this.sendError({message: "Questo pannello è per gli utenti news di City Up, per accedere al cms vai su: https://panel.cityup.eu"})
          this.logout()
          return
        }
        if(reply.user.role > consts.USER_ROLES.CUSTOMER) {
          //utente
          this.sendError({message: "Non hai i permessi per accedere a questo pannello."})
          this.logout()
          return
        }
        if(!reply.user.confirm) {
          this.logout();
          this.noUserConfirmDialog = true;
          return;
        }
        await this.refreshContracts();
        if(!this.hasNewsContract) {
          this.sendError({message: "Non hai i permessi per accedere a questo pannello."})
          this.logout()
          return
        }

        await this.refreshSchede();

        await this.$router.replace('/news');
      } catch (err) {
        this.onError(err)
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>

</style>