import config from "../config";

const LEVEL_DEBUG = 5
const LEVEL_INFO = 4
const LEVEL_WARNING = 3
const LEVEL_ERROR = 2
const LEVEL_CRITICAL = 1


const levelVal = ( level ) => {
    switch (level) {
        case 'critical' :
            return LEVEL_CRITICAL;
        case 'error' :
            return LEVEL_ERROR;
        case 'warning' :
            return LEVEL_WARNING;
        case 'info' :
            return LEVEL_INFO;
        case 'debug' :
        default:
            return LEVEL_DEBUG;
    }
}

const logLevel = {
    get() {
        return levelVal(config.logLevel)
    }
}


export default {
    d() { // debug
        if(!config.logEnabled && logLevel < LEVEL_DEBUG) return;
        console.debug('[DEBUG]', ...arguments);
    },
    i() { // info
        if(!config.logEnabled && logLevel < LEVEL_INFO) return;
        console.log('[INFO]', ...arguments);
    },
    w() { // warning
        if(!config.logEnabled && logLevel < LEVEL_WARNING) return;
        console.warn('[WARNING]', ...arguments);
    },
    e() { // error
        if(!config.logEnabled && logLevel < LEVEL_ERROR) return;
        console.error('[ERROR]', ...arguments);
    },
    c() { // critical
        console.error('[CRITICAL]', ...arguments);
    }
}