<template>
  <v-app style="background-color: lightgray">
    <v-navigation-drawer v-model="drawer" app clipped width="200" v-if="hasDrawer" class="gradient-ani d-flex" dark>
      <v-list dense>
        <v-list-item link v-for="page in pages" :key="page.link" :to="page.link" router active-class="accent--text">
          <v-list-item-action class="mr-3"><v-icon color="white">{{page.icon}}</v-icon></v-list-item-action>
          <v-list-item-content><v-list-item-title class="white--text">{{page.title}}</v-list-item-title></v-list-item-content>

        </v-list-item>


        <v-list-item @click="doLogout();" router active-class="font-weight-black">
          <v-list-item-action class="mr-3"><v-icon color="white">mdi-power</v-icon></v-list-item-action>
          <v-list-item-content class="white--text"><v-list-item-title>Esci</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app clipped-left class="gradient-ani" dark v-if="hasToolbar">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex align-center">
        <v-img class="d-block" max-height="40" max-width="40" src="./assets/logo-bianco.png"></v-img>
        <span class="gill-bold d-block ml-3 mt-1">NEWS</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

    </v-app-bar>
    <div v-if="hasGradientBg" class="gradient-ani full-width fill-height" style="position: fixed"></div>
    <v-main>
      <v-fade-transition>
        <router-view></router-view>
      </v-fade-transition>


      <v-dialog v-model="confirmDialog" persistent max-width="400" >
        <v-card>
          <v-card-title class="headline">{{confirmDialogTitle}}</v-card-title>
          <v-card-text>
            <p class="body-1">{{confirmDialogMessage}}</p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="confirmDialogCallback(false); confirmDialog = false">No</v-btn>
            <v-btn color="error" text @click.native="confirmDialogCallback(true); confirmDialog = false">Sì</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar app :timeout="5000" :color="snackbarColor" top multi-line v-model="snackbar">
        {{ dialogMessage }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import log from './log'
import {mapActions, mapGetters, mapState} from "vuex";
import router from "@/router";

export default {
  name: 'App',

  components: {
  },

  data: () => ({

  }),
  computed : {
    ...mapGetters(['profile','loggedIn','isRestrictedRoute','token','hasNewsContract']),
    ...mapState(['confirmDialogTitle','confirmDialogMessage','confirmDialogCallback', 'dialogMessage', 'snackbarColor']),
    pages(){
      return [
        { title : "Lista news" , icon : "mdi-newspaper", link : "/news" },
        { title : "Il mio account" , icon : "mdi-account", link : "/account" }
      ];
    },
    hasDrawer() {
      return this.loggedIn && this.$route.name !== 'login' && this.$route.name !== 'notfound'
    },
    hasToolbar() {
      return this.loggedIn && this.$route.name !== 'login' && this.$route.name !== 'notfound'
    },
    drawer : {
      get() { return this.$store.state.drawer; },
      set(newVal) { this.$store.state.drawer = newVal; }
    },
    snackbar : {
      get() { return this.$store.state.snackbar; },
      set(newVal) { this.$store.state.snackbar = newVal; }
    },
    confirmDialog : {
      get() { return this.$store.state.confirmDialog; },
      set(newVal) { this.$store.state.confirmDialog = newVal; }
    },
    hasGradientBg() {
      return ['login','notfound'].includes(this.$route.name)
    }
  },
  methods : {
    ...mapActions(['refreshToken','logout','refreshContracts','refreshSchede','refreshCollection','sendError']),
    doLogout(){
      this.logout();
    },
    async refreshSession() {
      if(!this.token) return
      try {
        return await this.refreshToken()
      } catch (e) {
        log.d(e)
        if(e.status && e.status === 401) {
          this.logout();
        }
      }
    }
  },
  async mounted() {
    await this.refreshCollection({collection:'cities'});
    if(!this.token) {
      this.logout();
      return
    }
    log.d("App.mounted()")
    setInterval(() => {
      this.refreshSession();
    }, 300000); // ogni 5 minuti
    await this.refreshSession();
    if(this.loggedIn) {
      await this.refreshContracts();
      await this.refreshSchede();

      if(!this.hasNewsContract) {
        this.sendError({message: "Non hai i permessi per accedere a questo pannello."});
        this.logout()
      }

    }
  }
};
</script>
