import router from "../router";
import log from "../log";

const logout = (state) => {
    log.d('logout')
    state.token = null;
    state.profile = null;
    sessionStorage.removeItem("token");
    log.w(router.currentRoute)
    if(router.currentRoute.name !== 'login' && router.currentRoute.name !== 'register' && router.currentRoute.name !== 'register-done') router.replace('/login')
}

export default {
    logout : logout,
    token : (state, { token }) => {
        log.d("setting token in mutation", token)
        state.token = token;
        if(token)
            sessionStorage.setItem("token" , token);
        else
            sessionStorage.removeItem("token");
    },
    profile : (state, { profile }) => {
        state.profile = profile;
    },
    message : (state, { type, message }) => {
        state.snackbarColor = type
        state.dialogMessage = message;
        state.snackbar = true;
    },
    confirm : (state, { title, message, callback }) => {
        state.confirmDialogTitle = title || 'Richiesta conferma';
        state.confirmDialogMessage = message || 'Sei sicuro?';
        state.confirmDialogCallback = callback || null;
        state.confirmDialog = true;
    },
    cities : (state, { data }) => {
        state.cities = data;
    },
    scheda : (state, { data }) => {
        state.scheda = data;
    },
    news : (state, { data }) => {
        state.news = data;
    },
    contracts : (state, { data }) => {
        state.contracts = data;
    },
    products : (state, { data }) => {
        state.products = data;
    },
    tags : (state, { data }) => {
        state.tags = data;
    },
};