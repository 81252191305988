import utils from "@/utils/index";

export default {
    email: value => {
        if(utils.isValidEmail(value)) {
            return true;
        }
        return "inserisci una mail valida";
    },
    maxchars: (num) => {
        return value => {
            if(value && value.length > num) {
                return `massimo ${num} caratteri`;
            }
            return true;
        }
    },
    notEmpty:(v)=> {
        if(v && v.length) return true;
        return 'campo obbligatorio'
    },
    required:(v)=> !!v || 'campo obbligatorio',
    password:(v)=>utils.isValidPassword(v) || 'una password deve avere almeno 8 caratteri tra i quali almeno una lettera, un numero ed un carattere speciale',
    confirmPassword: (v) => {
        return value => {
            if(value !== v) {
                return `la password e la conferma password devono essere uguali`;
            }
            return true;
        }
    },
}