<template>
  <v-container>
    <v-form ref="form" @submit.prevent="save(false)">
      <v-card>
        <v-card-title>
          <div class="d-flex full-width justify-space-between align-top pt-3">
            <h2 class="gill-bold" style="line-height: 0.8">Scheda notizia
              <span v-if="editingItem.id">
              <v-chip x-small color="success" v-if="editingItem.pub">pubblicato</v-chip>
              <v-chip x-small color="grey" v-else text-color="white">in bozza</v-chip>
            </span>
            </h2>
            <small class="gill-bold error--text mr-6">* campi obbligatori</small>
          </div>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="12">
              <h4 class="gill-bold">Titolo</h4>
              <small>Inserisci qui il titolo della news, sia in italiano che in inglese (massimo 80 caratteri)</small>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Titolo in italiano*" outlined v-model="titleIt" :counter="80" :rules="[rules.required, rules.maxchars(80)]"
                            append-outer-icon="mdi-clipboard-arrow-right" @click:append-outer="titleEn = titleIt"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Titolo in inglese*" outlined v-model="titleEn" :counter="80" :rules="[rules.required, rules.maxchars(80)]"
                            prepend-icon="mdi-google-translate" @click:prepend="translateTitle()"></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4 class="gill-bold">Sottotitolo</h4>
              <small>Inserisci qui il sottotitolo della news, sia in italiano che in inglese (massimo 160 caratteri)</small>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Sottotitolo in italiano*" outlined v-model="subtitleIt" :counter="160" :rules="[rules.required, rules.maxchars(160)]"
                            append-outer-icon="mdi-clipboard-arrow-right" @click:append-outer="titleEn = titleIt"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field label="Sottotitolo in inglese*" outlined v-model="subtitleEn" :counter="160" :rules="[rules.required, rules.maxchars(160)]"
                            prepend-icon="mdi-google-translate" @click:prepend="translateSubtitle()"></v-text-field>
            </v-col>
            <v-col cols="12">
              <h4 class="gill-bold">Contenuto</h4>
              <small>Inserisci qui il contenuto della notizia, sia in italiano che in inglese. (massimo 1600 caratteri)</small>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea label="Contenuto in italiano*" outlined v-model="descIt" :counter="1600" :rules="[rules.required, rules.maxchars(1600)]"
                          append-outer-icon="mdi-clipboard-arrow-right" @click:append-outer="descEn = descIt"></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea label="Contenuto in inglese*" outlined v-model="descEn" :counter="1600" :rules="[rules.required, rules.maxchars(1600)]"
                          prepend-icon="mdi-google-translate" @click:prepend="translateDesc()"></v-textarea>
            </v-col>
            <v-col cols="12">
              <h4 class="gill-bold">Categorie</h4>
              <small>Scegli qui le categorie di questa notizia (es. sport, cronaca, ultim'ora, ecc...).</small>
            </v-col>
            <v-col cols="12">
              <v-autocomplete outlined label="Categorie*" v-model="editingItem.tags" append-icon="mdi-tag" :items="tagOptions"
                              multiple chips small-chips deletable-chips ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <h4 class="gill-bold">Collegamenti</h4>
              <small>Inserisci qui, se presenti, i collegamenti legati a questa notizia.</small>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Collegamenti web" outlined v-model="urls" :rules="[rules.urls]" prepend-inner-icon="mdi-web"
                            persistent-hint hint="puoi inserire più indirizzi separati da virgola e spazio (es: http://foo.it, https://bar.it)"></v-text-field>
            </v-col>

            <v-col cols="12">
              <h4 class="gill-bold">Icona</h4>
              <small>Icona della notizia visualizzata all'interno della lista verticale delle news. In assenza di icona verrà visualizzato il logo della testata.</small>
            </v-col>
            <v-col class="d-flex flex-grow-1 flex-md-grow-0">
              <v-img :src="logoSrc" :aspect-ratio="1" width="180" height="180" max-width="180"
                     alt="Immagine logo" style="cursor: pointer" @click="openLogoInput()"
                     class="d-flex justify-center align-center grey text-center">
                <v-btn v-if="logoSrc" icon color="white" rounded absolute top right class="accent darken-1" x-small @click.stop="removeLogo()"><v-icon>mdi-close</v-icon></v-btn>
                <span v-else class="white--text caption" >Scegli file</span>
              </v-img>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
              <v-file-input ref="logoInput" class="d-flex" style="max-width: 300px" v-model="logoFile" outlined label="Scegli file" @change="openCropper('logo')"
                            hint="Clicca qui per caricare un nuovo logo" persistent-hint accept="image/*" />
            </v-col>
            <v-col cols="12">
              <h4 class="gill-bold">Copertina</h4>
              <small>La copertina in City Up è una immagine che viene visualizzata all'interno della scheda della notizia, in alto, come intestazione e nelle liste orizzontali di notizie (ad es. nella home della app).<br/>
                Se decidi di non inserire una copertina, verrà utilizzata l'immagine di copertina della scheda della tua testata.</small>
            </v-col>
            <v-col class="d-flex flex-grow-1 flex-md-grow-0 flex-shrink-0">
              <v-img :src="coverSrc"  :aspect-ratio="16/9" max-width="480" min-width="320" min-height="180" max-height="270"
                     alt="Immagine copertina" style="cursor: pointer" @click="openCoverInput()"
                     class="d-flex justify-center align-center grey text-center">
                <v-btn v-if="coverSrc" icon color="white" rounded absolute top right class="accent darken-1" x-small @click.stop="removeCover()"><v-icon>mdi-close</v-icon></v-btn>
                <span v-else class="white--text caption" >Scegli file</span>
              </v-img>
            </v-col>
            <v-col cols="12" md="6" class="d-flex">
              <v-file-input ref="coverInput" class="d-flex" style="max-width: 300px" v-model="coverFile" outlined label="Scegli file" @change="openCropper('cover')"
                            hint="Clicca qui per caricare una nuova copertina" persistent-hint accept="image/*"/>

            </v-col>
            <v-col cols="12" v-if="contract && contract.max_images > 0">
              <h4 class="gill-bold">Galleria immagini</h4>
              <small>La galleria immagini si trova all'interno della scheda della news.<br/>
                <b v-if="contract.max_images > 1">Puoi inserire un massimo di {{contract.max_images}} immagini per notizia</b>
                <b v-if="contract.max_images === 1">Puoi inserire una sola immagine per notizia</b>
              </small>
            </v-col>

            <v-col cols="6" sm="4" md="3" lg="2" xl="1" v-for="(item, i) in gallery" :key="'gall'+i" v-if="contract && contract.max_images > 0">
              <input type="file" accept="image/jpeg, image/png" :id="'galleryInput' + i" @change="onGalleryFileSelected" style="display: none">
              <v-img :src="getGalleryUrl(item)" :aspect-ratio="1" style="cursor: pointer" contain @click="openInput(i)"
                     class="d-flex justify-center align-center grey text-center">
                <v-btn v-if="item" icon color="white" rounded absolute top right class="accent darken-1" x-small @click.stop="removeGalleryItem(i)"><v-icon>mdi-close</v-icon></v-btn>
                <span v-else class="white--text caption" >Scegli file</span>
              </v-img>
            </v-col>

            <v-col cols="12">
              <h4 class="gill-bold">Data e ora notizia</h4>
              <small>Inserisci qua le data e l'ora della notizia.</small>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details type="date" v-model="date" label="Data" :error="!!dateError" :error-messages="dateError ? [dateError] : []" />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field outlined hide-details type="time" v-model="time" label="Ora" :error="!!timeError" :error-messages="timeError ? [timeError] : []"/>
            </v-col>

          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :loading="loading" class="flex-shrink-1 flex-grow-0 gill-bold ma-2" color="accent" @click="save(false)">Salva in bozza</v-btn>
          <v-btn :loading="loading" class="flex-shrink-1 flex-grow-0 gill-bold ma-2" color="accent" @click="save(true)">Pubblica</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-form>
    <v-dialog v-model="cropDialog" :max-width="cropRef === 'logo' ? 240 : 520" persistent>
      <v-card>
        <v-card-title><h4 class="gill-bold text-center full-width">Ritaglio immagine</h4></v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <vue-cropper v-if="cropDialog" ref="cropper" :aspect-ratio="cropRef === 'logo' ? 1 : 16/9" :class="cropRef === 'logo' ? 'logo-cropper' : 'cover-cropper'"></vue-cropper>
              <v-progress-linear v-if="cropDialogLoading" indeterminate color="accent" />
            </v-col>
            <v-col cols="12" v-if="!cropDialogLoading" class="d-flex justify-center">
              <v-btn icon color="accent" @click="$refs.cropper.rotate(-90)"><v-icon>mdi-rotate-left</v-icon></v-btn>
              <v-btn icon color="accent" @click="$refs.cropper.rotate(90)"><v-icon>mdi-rotate-right</v-icon></v-btn>
              <v-btn icon color="accent" @click="$refs.cropper.reset()"><v-icon>mdi-fullscreen</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn :disabled="cropDialogLoading" text @click="logoFile = null; coverFile = null; cropDialog = false">Annulla</v-btn>
          <v-btn :loading="cropDialogLoading" text color="accent" @click="crop()">Ritaglia</v-btn>
        </v-card-actions>
      </v-card>
      <!--
      -->
    </v-dialog>

  </v-container>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import utils from "../utils";
import rules from "../utils/rules";
import log from "../log";
import {mapActions, mapGetters} from "vuex";
import _ from 'lodash'
import moment from 'moment'
import goTo from 'vuetify/es5/services/goto'
import consts from "../utils/consts";
export default {
  name: "EventForm",
  components : {
    VueCropper
  },
  data () {
    return {
      loading : false,
      day : '',
      dayName : '',
      date : moment().format('YYYY-MM-DD'),
      time : moment().format('HH:mm'),
      dateError : '',
      timeError : '',
      cropDialog : false,
      cropDialogLoading : false,
      cropRef : 'logo',
      logoCropDialog : false,
      logoCropDialogLoading : false,
      logoBlob : null,
      logoFile : null,
      coverBlob : null,
      coverFile : null,
      search : null,
      contract : null,
      logoError : false,
      coverError : false,
      rules : {
        ...rules,
        urls : (v) => {
          if(!v) return true
          let vals = v.split(", ")
          for(let i = 0; i < vals.length; i++) {
            if(!utils.isValidUrl(vals[i])) return "inserisci un indirizzo valido (deve iniziare con http:// o https://)"
          }
          return true;
        },
      },
      editingItem : {
        id_contract : null,
        id_parent : null,
        type: 'news',
        city : '',
        title : [],
        subtitle : [],
        desc : [],
        services : [],
        contacts : [],
        tags : [],
        loc : null,
        logo : '',
        cover : '',
        images : [],
        attachments : [],
        orari : null,
        pub : false,
      },
      galleryBlobs : [],
      contactTypes: [
        { value : 'phone', text : 'Telefono', is_action : true },
        { value : 'fax', text : 'Fax', is_action : false },
        { value : 'mail', text : 'Email', is_action : true },
        { value : 'url', text : 'URL', is_action : true },
        { value : 'delivery', text : 'Delivery', is_action : false },
        { value : 'address', text : 'Indirizzo', is_action : false },
        { value : 'other', text : 'Altro', is_action : false },
        { value : 'facebook', text : 'Facebook', is_action : false },
        { value : 'twitter', text : 'Twitter', is_action : false },
        { value : 'instagram', text : 'Instagram', is_action : false },
        { value : 'youtube', text : 'YouTube', is_action : false },
      ],
      tagOptions : [],
    };
  },
  computed : {
    ...mapGetters(['contracts','cities','tags','scheda','news']),
    gallery() {
      if(!this.editingItem.images) {
        this.editingItem.images = [];
      }
      let items = this.editingItem.images.concat(this.galleryBlobs)
      let maxImages = this.contract ? this.contract.max_images : 0;
      if(items.length < maxImages) {
        items = items.concat([null])
      }
      return items;
    },
    logoSrc() {
      if(this.logoBlob) {
        return URL.createObjectURL(this.logoBlob)
      }
      if(this.editingItem.logo) {
        return this.editingItem.logo
      }
      return null
    },
    coverSrc() {
      if(this.coverBlob) {
        return URL.createObjectURL(this.coverBlob)
      }
      if(this.editingItem.cover) {
        return this.editingItem.cover
      }
      return null
    },
    cityData() {
      if(!this.contract) return null;
      let city = this.contract.city;
      let filter = this.cities.filter((item) => item.uri === city)
      if(!filter.length) return null
      return filter[0]
    },
    cityLat() {
      const city = this.cityData
      if(!city) return 0;
      return city.lat;
    },
    cityLng() {
      const city = this.cityData
      if(!city) return 0;
      return city.lng;
    },
    lat: {
      get() {
        if(!this.editingItem.loc || !this.editingItem.loc.coordinates || this.editingItem.loc.coordinates.length < 2) {
          return this.cityLat
        }
        if(this.editingItem.loc.coordinates[1] === 0) {
          this.editingItem.loc.coordinates[1] = this.cityLat;
        }
        return this.editingItem.loc.coordinates[1];
      },
      set(newVal) {
        if(!this.editingItem.loc || !this.editingItem.loc.coordinates || this.editingItem.loc.coordinates.length < 2) {
          this.editingItem.loc = {
            type : 'Point',
            coordinates: [this.cityLng, newVal]
          }
          return
        }
        this.editingItem.loc.coordinates[1] = newVal
      }
    },
    lng: {
      get() {
        if(!this.editingItem.loc || !this.editingItem.loc.coordinates || this.editingItem.loc.coordinates.length < 2) {
          return this.cityLng
        }
        if(this.editingItem.loc.coordinates[0] === 0) {
          this.editingItem.loc.coordinates[0] = this.cityLng;
        }
        return this.editingItem.loc.coordinates[0];
      },
      set(newVal) {
        if(!this.editingItem.loc || !this.editingItem.loc.coordinates || this.editingItem.loc.coordinates.length < 2) {
          this.editingItem.loc = {
            type : 'Point',
            coordinates: [newVal, this.cityLat]
          }
          return
        }
        this.editingItem.loc.coordinates[0] = newVal
      }
    },
    titleIt : {
      get() { return utils.getLangVal(this.editingItem.title, 'it') },
      set(newVal) {
        this.editingItem.title = utils.setLangVal(this.editingItem.title, 'it', newVal)
      }
    },
    titleEn : {
      get() { return utils.getLangVal(this.editingItem.title, 'en') },
      set(newVal) {
        this.editingItem.title = utils.setLangVal(this.editingItem.title, 'en', newVal)
      }
    },
    subtitleIt : {
      get() { return utils.getLangVal(this.editingItem.subtitle, 'it') },
      set(newVal) {
        this.editingItem.subtitle = utils.setLangVal(this.editingItem.subtitle, 'it', newVal)
      }
    },
    subtitleEn : {
      get() { return utils.getLangVal(this.editingItem.subtitle, 'en') },
      set(newVal) {
        this.editingItem.subtitle = utils.setLangVal(this.editingItem.subtitle, 'en', newVal)
      }
    },

    descIt : {
      get() { return utils.getLangVal(this.editingItem.desc, 'it') },
      set(newVal) {
        this.editingItem.desc = utils.setLangVal(this.editingItem.desc, 'it', newVal)
      }
    },
    descEn : {
      get() { return utils.getLangVal(this.editingItem.desc, 'en') },
      set(newVal) {
        this.editingItem.desc = utils.setLangVal(this.editingItem.desc, 'en', newVal)
      }
    },
    servicesIt : {
      get() { return utils.getLangVal(this.editingItem.services, 'it') },
      set(newVal) {
        this.editingItem.services = utils.setLangVal(this.editingItem.services, 'it', newVal)
      }
    },
    servicesEn : {
      get() { return utils.getLangVal(this.editingItem.services, 'en') },
      set(newVal) {
        this.editingItem.services
            = utils.setLangVal(this.editingItem.services, 'en', newVal)
      }
    },
    address : {
      get() {
        if(!this.editingItem.contacts || !this.editingItem.contacts.length) return ''
        let filter = this.editingItem.contacts.filter((item) => item.type === 'address')
        if(!filter.length) return '';
        return filter[0].value;
      },
      set(newVal) {
        if(!this.editingItem.contacts || !this.editingItem.contacts.length) {
          this.editingItem.contacts = [{type:'address', value:newVal, is_action: false}]
          return;
        }
        for(let i = 0; i < this.editingItem.contacts.length; i++) {
          if(this.editingItem.contacts[i].type === 'address') {
            this.editingItem.contacts[i].value = newVal
            return
          }
        }
        this.editingItem.contacts.push({type:'address', value:newVal, is_action: false})
      }
    },
    emails : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'mail')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'mail', true)
      }
    },
    urls : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'url')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'url', true)
      }
    },
    phones : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'phone')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'phone', true)
      }
    },
    faxes : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'fax')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'fax', false)
      }
    },
    facebook : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'facebook')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'facebook', false)
      }
    },
    instagram : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'instagram')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'instagram', false)
      }
    },
    twitter : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'twitter')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'twitter', false)
      }
    },
    youtube : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'youtube')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'youtube', false)
      }
    },
    delivery : {
      get() {
        return utils.getContactsFormatted(this.editingItem.contacts, 'delivery')
      },
      set(newVal) {
        this.editingItem.contacts = utils.setContactValue(this.editingItem.contacts, newVal, 'delivery', false)
      }
    }
  },
  methods : {
    ...mapActions(['translate','sendError','sendSuccess','refreshContracts','refreshSchede','refreshCollection','insert','remove','update','upload','requestConfirm']),
    numNewsOn(idContract, when) {
      let filter = this.news.filter((item) => {
        if(item.id_contract !== idContract) return false
        let date = moment(item.insert_date)
        return date.isSame(when, 'day')
      })
      return filter.length;
    },
    checkDates( withMessages ) {
      log.d('checkDates() withMessages', withMessages)
      if(withMessages) this.dateError = '';
      if(!this.date) {
        log.d('no date')
        if(withMessages) this.dateError = "Inserisci la data della notizia"
        return false
      }

      let date = moment(this.date, 'YYYY-MM-DD')
      if(!date.isValid()) {
        log.d('invalid dates')
        if(withMessages) this.dateError = "Inserisci una data valida"
        return false;
      }
      let now = moment()
      if(date.isBefore(now,'day')) {
        log.d('before date')
        if(withMessages) this.dateError = "Non puoi inserire una notizia con una data antecedente ad oggi"
        return false;
      }

      let numNews = this.numNewsOn(this.scheda.id_contract, date);
      if(numNews >= this.contract.max_news) {
        log.d('max news reched for date')
        if(withMessages) this.dateError = "Hai già raggiunto il limite di notizie inserite per questa data";
        return false;
      }

      if(!this.time) {
        log.d('no times')
        if(withMessages) this.timeError = "Inserisci sia l'orario della notizia"
        return false
      }
      let t = moment(`${this.date} ${this.time}`, 'YYYY-MM-DD HH:mm');
      if(!t.isValid()) {
        if(withMessages) this.timeError = "Inserisci un orario valido"
        return false
      }
      this.editingItem.insert_date = t.toJSON()
      return true;
    },
    getGalleryUrl( item ) {
      if(!item) return null;
      if(_.isString(item)) return item;
      return URL.createObjectURL(item);
    },
    openInput( idx ) {
      let input = document.getElementById('galleryInput'+idx)
      input.click()
    },
    onGalleryFileSelected( e ) {
      if(!this.editingItem.images) {
        this.editingItem.images = [];
      }

      if(e.target.files && e.target.files.length) {
        let idx = utils.toInt(e.target.id.replace('galleryInput',''), -1)
        if(idx > -1) {
          idx = idx - this.editingItem.images.length
          if(this.galleryBlobs.length === idx) {
            this.galleryBlobs.push(e.target.files[0])
          } else {
            this.galleryBlobs.splice(idx, 1, e.target.files[0]);
          }
          return
        }
        this.galleryBlobs.push(e.target.files[0])


        /*
        const reader = new FileReader();
        reader.onload = (event) => {
          // rebuild cropperjs with the updated source
          this.galleryBlobs.push(event.target.result)

        };
        reader.readAsDataURL(e.target.files[0]);
*/
      }
    },
    removeGalleryItem(idx) {
      if(!this.editingItem.images) {
        this.editingItem.images = [];
      }
      if(idx < this.editingItem.images.length) {
        this.editingItem.images.splice(idx, 1);
      } else {
        idx -= this.editingItem.images.length
        this.galleryBlobs.splice(idx, 1);
      }
    },
    openCropper( ref ) {
      this.cropRef = ref
      this.cropDialog = true
      this.cropDialogLoading = true
      const reader = new FileReader();
      reader.onload = (event) => {
        // rebuild cropperjs with the updated source

        this.$refs.cropper.replace(event.target.result);
        this.cropDialogLoading = false
      };
      let file = ref === 'logo' ? this.logoFile : this.coverFile;
      reader.readAsDataURL(file);
    },
    async crop() {
      this.cropDialogLoading = true;
      let width = this.cropRef === 'logo' ? 250 : 720
      let height = this.cropRef === 'logo' ? 250 : 405

      this.$refs.cropper.getCroppedCanvas({width, height}).toBlob((blob)=>{
        if( this.cropRef === 'logo' ) {
          this.logoBlob = blob;
          this.logoFile = null
        } else {
          this.coverBlob = blob;
          this.coverFile = null
        }

        this.cropDialogLoading = false
        this.cropDialog = false
      }, 'jpeg', 0.8)
    },
    async removeLogo() {
      this.editingItem.logo = "";
      this.logoFile = null;
      this.logoBlob = null;
    },
    async removeCover() {
      this.editingItem.cover = "";
      this.coverFile = null;
      this.coverBlob = null;
    },
    openLogoInput() {
      this.$refs.logoInput.$el.querySelector('input[type=file]').click()
    },
    openCoverInput() {
      this.$refs.coverInput.$el.querySelector('input[type=file]').click()
    },
    async translateTitle() {
      if(!this.titleIt) return
      try {
        this.titleEn = await this.translate({ text : this.titleIt })
      } catch (e) {
        log.d("translation error:", e)
        this.sendError({message: "Impossibile tradurre automaticamente, perfavore inserisci una traduzione manualmente."});
      }
    },
    async translateSubtitle() {
      if(!this.subtitleIt) return
      try {
        this.subtitleEn = await this.translate({ text : this.subtitleIt })
      } catch (e) {
        log.d("translation error:", e)
        this.sendError({message: "Impossibile tradurre automaticamente, perfavore inserisci una traduzione manualmente."});
      }
    },
    async translateDesc() {
      if(!this.descIt) return
      try {
        this.descEn = await this.translate({ text : this.descIt })
        this.descEn += "\r\nTranslated by Google Translate."
      } catch (e) {
        log.d("translation error:", e)
        this.sendError({message: "Impossibile tradurre automaticamente, perfavore inserisci una traduzione manualmente."});
      }
    },
    async buildTagOptions() {
      log.d("buildTagOptions()")
      let parents = {}
      let opts = [];
      let len = this.tags.length
      for(let i = 0; i < len; i++) {
        const item  = this.tags[i];
        if(!item.is_news_tag) {
          continue;
        }
        if(!item.parent) {
          parents[item.tag] = item;
        } else {
          opts.push(item)
        }
      }
      this.tagOptions = opts.map((item)=>{
        let text = ""
        let parent = parents[item.parent]
        if(parent) {
          text = utils.getLangVal(parent.label, 'it') + " > "
        }
        text += utils.getLangVal(item.label, 'it')
        return { text, value : item.tag}
      })
      return true
    },
    async saveMedia(path, blob) {
      let data = new FormData()
      data.append("media", blob);
      let reply = await this.upload({ path, data })
      if(reply.data) {
        return reply.data;
      }
      return null
    },
    async save( pub ) {
      if(!this.checkDates(true) || !this.$refs.form.validate()) {
        log.d("check form or dates fail")
        return
      }
      this.loading = true;
      this.editingItem.pub = pub;
      let data = this.editingItem;
      try {
        let reply = await this.insert({collection:'drafts', data})
        if(reply.data) {
          this.editingItem = reply.data;
        } else {
          this.sendError({message:"errore al salvataggio della scheda, per favore riprova più tardi."});
          this.loading = false;
          return
        }
        if(this.logoBlob && this.editingItem.id) {
          reply = await this.saveMedia(`drafts/${this.editingItem.id}/${this.editingItem.city}/logo`, this.logoBlob)
          if(reply) this.editingItem = reply;
        }
        if(this.coverBlob && this.editingItem.id) {
          reply = await this.saveMedia(`drafts/${this.editingItem.id}/${this.editingItem.city}/cover`, this.coverBlob)
          if(reply) this.editingItem = reply;
        }
        if(this.galleryBlobs && this.galleryBlobs.length) {
          let replies = await Promise.all(
              this.galleryBlobs.map(async blob => {
                return await this.saveMedia(`drafts/${this.editingItem.id}/${this.editingItem.city}/gallery`, blob)
              })
          )
          // ordino per ottenere l'ultima risposta (quella con array immagini più lungo)
          replies.sort((a, b) => {
            if(!a && !b) return 0;
            if(!a) return 1
            if(!b) return -1;
            let aLenght = a.images.length || 0;
            let bLenght = b.images.length || 0;
            return bLenght - aLenght
          })
          log.d("replies", replies)
          let lastReply = replies.shift()
          if(lastReply) this.editingItem = lastReply
        }
        this.sendSuccess({message:'Scheda salvata!'})
        this.loading = false;
        await this.refreshSchede()
        if(this.$route.params.id === 'new') {
          await this.$router.replace('/news/'+this.editingItem.id)
        }
      } catch (e) {
        this.sendError({message:"errore al salvataggio della scheda, per favore riprova più tardi."});
        log.d("error on save", e)
        this.loading = false;
      }
    }
  },
  async mounted() {
    try {
      await this.refreshContracts()
      await this.refreshSchede()
      this.contract = utils.getObjectInArray(this.contracts, 'id', this.scheda.id_contract)

      if(this.$route.params.id !== 'new') {
        let editingFilter = this.news.filter(item => item.id === this.$route.params.id);
        if(editingFilter.length) {
          this.editingItem = editingFilter[0];
          let d = moment(this.editingItem.insert_date);
          this.date = d.format('YYYY-MM-DD');
          this.time = d.format('HH:mm');
          log.d("editingItem", this.editingItem)
        } else {
          await this.$router.replace('/notfound')
          return
        }
      }

      if(this.$route.params.id === 'new') {
        this.editingItem.id_parent = this.scheda.id;
        this.editingItem.id_contract = this.scheda.id_contract;
        this.editingItem.city = this.scheda.city;
      }
      let ok = await this.refreshCollection({collection:'tags'})
      if(ok) {
        await this.buildTagOptions()
      }
    } catch (e) {
      log.e("mounted error:", e)
    }
  }
}
</script>

