import Vue from 'vue'
import VueRouter from 'vue-router'
import Account from '../pages/Account'
import Login from '../pages/Login'
import NotFound from "../pages/NotFound";
import News from "../pages/News";
import NewsForm from "../pages/NewsForm";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/account',
    name: 'account',
    component: Account
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:id',
    name: 'news form',
    component: NewsForm
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFound
  },
  { path: '/', redirect: '/news' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
