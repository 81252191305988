const LEVEL = "preprod";

const commons = {
    comingSoon : true,
    googleApiKey : 'AIzaSyCDS1RiWnZj0_tIf9E6wFeVV5KEfQa7kqI',
    defaultLanguage : 'it',
    capSmallTextSize : 28,
    cityOptions : [
        {text:"Assisi", value:"as"},
        {text:"Fossombrone", value:"fs"},
        {text:"Perugia", value:"pg"},
    ],
};

const dev = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'http://localhost:8000',
};
const preprod = {
    debug : true,
    logEnabled : true,
    logLevel : 'debug', // debug, info, warning, error
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'https://dev.cityup.eu',
};

const prod = {
    debug : false,
    logEnabled : false,
    logLevel : 'warning', // debug, info, warning, error
    //googleMapApiKey : 'AIzaSyC8hkqKR6ugzF1yUmy5d7vB49pTRhs9UTg',
    server : 'https://api.cityup.eu',
};

let config;

switch (LEVEL) {
    case "prod":
        config = Object.assign(commons, prod);
        break;
    case "preprod":
        config = Object.assign(commons, preprod);
        break;
    case "dev":
    default:
        config = Object.assign(commons, dev);
        break;
}

export default config;