export default {
    USER_ROLES : {
        GUEST : 0,
        USER : 1,
        CUSTOMER : 2,
        EDITOR : 3,
        COORDINATOR : 4,
        ADMIN : 5,
        ROOT : 6,
    },
    SCHEDA_TYPE : {
        LOCALE : 'locale',
        EVENTO : 'evento',
        PROMO : 'promo',
        NEWS : 'news',
    }
}