<template>
  <v-container>
    <div class="d-flex justify-center align-center fill-height full-width ">
      <v-row style="max-width: 400px">
        <v-col cols="12" class="text-center">
          <a href="/"><v-img class="d-inline-flex" src="../assets/logo-bianco.png" style="width: 100%; height: auto" contain max-width="240px" /></a>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon size="240" color="accent">mdi-emoticon-dead-outline</v-icon>
          <h1 class="display-3 white--text">Oh no!</h1>
          <h2 class="display-1 white--text">La pagina che hai richiesto non esiste</h2>
          <v-btn small color="white" class="gill-bold accent--text mt-3" @click="$router.replace('/')">Torna alla home</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

export default {
  name: "NotFound",
  data() {
    return {}
  },
  computed : {

  },
  methods : {

  }
}
</script>
