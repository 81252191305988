var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',{staticClass:"gill-bold d-flex align-center"},[_vm._v("Le mie notizie "),_c('v-spacer'),_c('v-btn',{staticClass:"gill-bold",attrs:{"small":"","color":"accent"},on:{"click":function($event){return _vm.openForm('new' )}}},[_vm._v("Nuova")])],1),_c('v-card-text',[_c('small',{staticClass:"caption"},[_vm._v(" Ogni giorno hai diritto ad inserire: ")]),_c('ul',{staticClass:"caption"},_vm._l((_vm.contracts),function(contract){return _c('li',{key:'li_'+contract.id},[_c('b',[_vm._v(_vm._s(contract.max_news))]),_vm._v(" notizie in "),_c('b',[_vm._v("City Up "+_vm._s(_vm.getCityName(contract.city)))]),_vm._v(", oggi te ne restano: "),_c('b',[_vm._v(_vm._s(_vm.remainingNewsOf(contract)))])])}),0),_c('v-row',{staticClass:"mt-3"},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","label":"Cerca","clearable":""},model:{value:(_vm.titleSearch),callback:function ($$v) {_vm.titleSearch=$$v},expression:"titleSearch"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-switch',{staticClass:"ma-0",attrs:{"hide-details":"","dense":"","label":"Nascondi vecchie notizie"},model:{value:(_vm.hideOld),callback:function ($$v) {_vm.hideOld=$$v},expression:"hideOld"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading,"no-data-text":"Non ci sono notizie.","footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.cover",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"black",attrs:{"src":_vm.coverUrl(item),"height":"90","width":"160","contain":""}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){return _vm.$router.push(("/news/" + (item.id)))}}},[_c('b',[_vm._v(_vm._s(_vm.getLngVal(item.title)))])])]}},{key:"item.subtitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLngVal(item.subtitle))+" ")]}},{key:"item.insert_date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatDateTime(item.insert_date)))])]}},{key:"item.pub",fn:function(ref){
var item = ref.item;
return [(item.pub)?_c('v-chip',{attrs:{"x-small":"","color":"success"}},[_vm._v("pubblicata")]):_c('v-chip',{attrs:{"x-small":"","color":"grey","text-color":"white"}},[_vm._v("in bozza")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/news/" + (item.id)))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","text":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }