<template>
  <v-container>
    <v-card>
      <v-card-title class="gill-bold d-flex align-center">Le mie notizie
        <v-spacer/>
        <v-btn small class="gill-bold" color="accent" @click="openForm('new' )">Nuova</v-btn></v-card-title>
      <v-card-text>
        <small class="caption">
          Ogni giorno hai diritto ad inserire:
        </small>
        <ul class="caption">
          <li v-for="contract in contracts" :key="'li_'+contract.id"><b>{{contract.max_news}}</b> notizie in <b>City Up {{getCityName(contract.city)}}</b>, oggi te ne restano: <b>{{remainingNewsOf(contract)}}</b></li>
        </ul>

        <v-row class="mt-3">
          <v-col cols="12" sm="6" md="4" lg="3" class="d-flex align-center">
            <v-text-field hide-details outlined dense v-model="titleSearch" label="Cerca" clearable></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3" class="d-flex align-center">
            <v-switch hide-details dense v-model="hideOld" label="Nascondi vecchie notizie" class="ma-0"></v-switch>
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                          no-data-text="Non ci sono notizie." :footer-props="footerProps">

              <template v-slot:item.cover="{ item }">
                <v-img :src="coverUrl(item)" height="90" width="160" contain class="black"></v-img>
              </template>
              <template v-slot:item.title="{ item }">
                <a @click="$router.push(`/news/${item.id}`)" class="text-decoration-underline" ><b>{{ getLngVal(item.title) }}</b></a>
              </template>
              <template v-slot:item.subtitle="{ item }">
                {{getLngVal(item.subtitle)}}
              </template>
              <template v-slot:item.insert_date="{ item }">
                <span class="font-weight-bold">{{ formatDateTime(item.insert_date) }}</span>
              </template>

              <template v-slot:item.pub="{ item }">
                <v-chip x-small color="success" v-if="item.pub">pubblicata</v-chip>
                <v-chip x-small color="grey" v-else text-color="white">in bozza</v-chip>
              </template>

              <template v-slot:item.edit="{ item }">
                <v-btn color="accent" dark text icon v-on:click="$router.push(`/news/${item.id}`)">
                  <v-icon dark>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.remove="{ item }">
                <v-btn color="error" dark text icon v-on:click="requestDelete(item.id)">
                  <v-icon dark>mdi-close</v-icon>
                </v-btn>
              </template>

            </v-data-table>
          </v-col>
        </v-row>


      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import utils from "../utils";
import log from "../log";
import moment from "moment";

export default {
  name: "News",
  data() {
    return {
      refreshTimeout : -1,
      titleSearch : null,
      hideOld : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : 'n° notizie per pagina',
        "items-per-page-options" : [10,20],
        "items-per-page" : 20
      },
      count : 0,
      items : [],
      headers : [
        { text : 'Copertina', sortable: false, align: 'left', value : 'cover', width: 160 },
        { text : 'Titolo', sortable: false, align: 'left', value : 'title' },
        { text : 'Subtitle', sortable: false, align: 'left', value : 'subtitle' },
        { text : 'Data e ora', sortable: true, align: 'left', value : 'insert_date' },
        { text : 'Stato', sortable: true, align: 'left', value : 'pub', width : 110 },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 50 },
        { text : 'Elimina', sortable: false, align: 'center', value : 'remove', width : 50 },
      ],
      pagination: {},
      query : {
        type : 'news',
      }
    };
  },
  watch: {
    hideOld() {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(this.refresh, 100);
    },
    titleSearch() {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = setTimeout(this.refresh, 100);
    }
  },
  computed: {
    ...mapGetters(['contracts', 'cities','news','scheda']),
    remainingNews() {
      let total = 0;
      for(let i = 0; i < this.contracts.length; i++) {
        let contract = this.contracts[i];
        if(contract.status === 'enabled') {
          let numInserted = this.numNewsToday(contract.id)
          log.e('max news', contract.max_news)
          total += Math.max(contract.max_news - numInserted, 0)
        }
      }
      log.e('total news', total)
      return total
    },

    opts() {
      return this.news.map((item)=>{
        let cityName = this.getCityName(item.city)
        let contract = this.getContract(item.id_contract)
        let parent = this.scheda;

        let title = utils.getLangVal(item.title, 'it')
        let logo = item.logo || parent.logo || null;
        let pub = item.pub
        let enabled = contract.status === "enabled"
        let date = moment(item.insert_date)
        return { id : item.id, city : item.city, cityName, enabled, title, logo, pub, date }
      })
    }
  },
  methods: {
    ...mapActions(['requestConfirm','sendError','sendSuccess','load']),
    getLngVal( val ) {
      return utils.getLangVal(val, 'it');
    },
    coverUrl(item) {
      return item.cover ? item.cover : this.scheda.cover;
    },
    isOld(item) {
      let now = moment()
      let date = moment(item.insert_date)
      return date.isBefore(now,'day');
    },
    formatDateTime( date ) {
      return utils.formatDateTime(date);
    },
    dateClass(item) {
      let now = moment()
      let date = moment(item.insert_date)
      if(date.isBefore(now,'day')) return 'ended';
      return 'ongoing'
    },
    remainingNewsOf(contract) {
      let numInserted = this.numNewsToday(contract.id)
      return Math.max(contract.max_news - numInserted, 0);
    },
    numNewsToday(idContract) {
      let filter = this.news.filter((item) => {
        if(item.id_contract !== idContract) return false
        let now = moment()
        let date = moment(item.insert_date)
        return date.isSame(now, 'day')
      })
      return filter.length;
    },
    getCityName(uri) {
      let city = utils.getObjectInArray(this.cities, 'uri', uri)
      return city ? city.name : uri;
    },
    getContract( id ) {
      return utils.getObjectInArray(this.contracts, 'id', id)
    },
    getScheda( id ) {
      return utils.getObjectInArray(this.news, 'id', id)
    },
    async refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) {
          order = 'DESC';
        }
      }

      this.query.hide_old = this.hideOld;
      this.query.q = this.titleSearch;

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page,
        sort,
        order
      };

      this.load({ collection : 'my/schede', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"there was an error while loading data"});
        log.d("Errore al caricamento delle news: ", err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richista cancellazionet', message : 'Sei sicuro di voler eliminare permanentemente questa notizia?', callback: ( accepted )=>{
          if(accepted) {
            this.delete({ collection : 'schede', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Notizia eliminata!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "a causa di un errore non è stato possibile rimuovere la notizia, riprova più tardi";
              this.sendError({ message });
              log.d("Errore di cancellazione della news",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    openForm( id ) {
      this.$router.push(`/news/${id}`)
    }
  },
  mounted() {
    let wait = setInterval(() => {
      if(!this.scheda) return;
      this.query.id_contract = this.scheda.id_contract;
      this.refresh();
      clearInterval(wait);
    }, 500);
  }
}
</script>

